import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

import { date_get } from '@thomasphan/vue-components/src/functions/date_get'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    // component: Home
    redirect: () => `/diary/${ date_get() }`,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/recipe/create',
    name: 'RecipeCreate',
    component: () => import(/* webpackChunkName: "recipe-create" */ '../views/RecipeCreate.vue')
  },
  {
    path: '/recipe/get',
    name: 'RecipeGet',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/recipes/get',
    name: 'RecipesGet',
    component: () => import(/* webpackChunkName: "recipes-get" */ '../views/RecipesGet.vue')
  },
  {
    path: '/recipe/edit',
    name: 'RecipeEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/recipe/copy',
    name: 'RecipeCopy',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/recipe/ingredient',
    name: 'RecipeIngredient',
    component: () => import(/* webpackChunkName: "recipe-ingredient" */ '../views/RecipeIngredient.vue')
  },
  {
    path: '/diary',
    name: 'Diary',
    redirect: () => `/diary/${ date_get() }`,
  },
  {
    path: '/weight',
    name: 'Weight',
    redirect: () => `/weight/${ date_get() }`,
  },
  {
    path: '/diary/:date(\\d{4}-\\d{2}-\\d{2})',
    name: 'DiaryDay',
    props: true,
    component: () => import(/* webpackChunkName: "diary" */ '../views/Diary.vue')
  },
  {
    path: '/weight/:date(\\d{4}-\\d{2}-\\d{2})',
    name: 'WeightDay',
    props: true,
    component: () => import(/* webpackChunkName: "weight" */ '../views/Weight.vue')
  },
  {
    path: '/water/:date(\\d{4}-\\d{2}-\\d{2})',
    name: 'Water',
    props: true,
    component: () => import(/* webpackChunkName: "water" */ '../views/Water.vue')
  },
  {
    path: '/water',
    name: 'WaterRedirect',
    redirect: () => `/water/${ date_get() }`,
  },
  {
    path: '/notes/:date(\\d{4}-\\d{2}-\\d{2})',
    name: 'Notes',
    props: true,
    component: () => import(/* webpackChunkName: "notes" */ '../views/Notes.vue')
  },
  {
    path: '/notes',
    name: 'NotesRedirect',
    redirect: () => `/notes/${ date_get() }`,
  },
  {
    path: '/exercise/:date(\\d{4}-\\d{2}-\\d{2})',
    name: 'Exercise',
    props: true,
    component: () => import(/* webpackChunkName: "exercise" */ '../views/Exercise.vue')
  },
  {
    path: '/exercise',
    name: 'ExerciseRedirect',
    redirect: () => `/exercise/${ date_get() }`,
  },
  {
    path: '/foods/search/:date(\\d{4}-\\d{2}-\\d{2})',
    name: 'FoodsSearch',
    props: true,
    component: () => import(/* webpackChunkName: "foodsSearch" */ '../views/Home.vue')
  },
  {
    path: '/foods/search',
    name: 'FoodsSearchRedirect',
    redirect: () => `/foods/search/${ date_get() }`,
  },
  {
    path: '/goals/:date(\\d{4}-\\d{2}-\\d{2})',
    name: 'Goals',
    props: true,
    component: () => import(/* webpackChunkName: "goals" */ '../views/Goals.vue')
  },
  {
    path: '/barcode/:barcode',
    name: 'BarcodeView',
    props: true,
    component: () => import(/* webpackChunkName: "barcode" */ '../views/BarcodeView.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
  },
  {
    path: '/daily-nutrition-goals',
    name: 'Daily Nutrition Goals',
    component: () => import(/* webpackChunkName: "dailyNutritionGoals" */ '../views/DailyNutritionGoals.vue')
  },
  {
    path: '/menu',
    name: 'Menu',
    component: () => import(/* webpackChunkName: "menu" */ '../views/Menu.vue')
  },
  {
    path: '/achievements',
    name: 'Achievements',
    component: () => import(/* webpackChunkName: "achievements" */ '../views/Achievements.vue')
  },
  {
    path: '/getting-started',
    name: 'GettingStarted',
    component: () => import(/* webpackChunkName: "gettingStarted" */ '../views/GettingStarted.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
