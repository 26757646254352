<template lang='pug'>
v-app
  v-app-bar(
    app
    dark
    flat
  )
    v-btn.mr-2(
      v-if='$store?.state?.currentUser !== null'
      text
      to='/menu'
    )
      | #[v-icon(left) mdi-menu]{{ VUE_APP_SITE_NAME }}
    v-btn.mr-2.d-none.d-sm-flex(
      v-if='$store?.state?.currentUser !== null'
      text
      to='/diary'
    )
      | Diary
    //- v-btn.mr-2.d-none.d-sm-flex(
    //-   text
    //-   to='/getting-started'
    //- )
    //-   | Getting Started
    //- v-btn.mr-2.d-none.d-sm-flex(
    //-   text
    //-   href='https://platform.fatsecret.com'
    //-   x-small
    //-   color='grey'
    //- )
    //-   | Powered by FatSecret
    v-spacer
    TheUserIcon
  v-main
    CurrentUser(
      @onAuthStateChanged='currentUser => $store.commit("currentUserUpdate", currentUser)'
    )
    v-container(
      v-if='VUE_APP_SITE_NAME === "EATANDTRACK" && $store?.state?.currentUser === null'
    )
      v-row
        v-col
          v-card(
            dark
            flat
          )
            v-card-title
              | Welcome to EatAndTrack.com!
            v-card-text
              | As a guest, you have full access to explore and experience our app's fantastic features for demo purposes. However, to fully enjoy the benefits and unlock the complete range of functionalities, we encourage you to sign up for an account.
            v-card-actions
              v-spacer
              ButtonLoading#continue_as_guest(
                @loading='shouldContinueAsGuest = true'
                :disabled='false'
              )
                template(
                  v-slot:label
                )
                  | Continue as guest
              v-spacer
      v-row
        v-col
          CardGettingStarted
    Uid(
      v-if='$store?.state?.currentUser !== null || shouldContinueAsGuest'
    )
      ProgressInitFatsecretApi(
        v-slot='{ start, stop }'
      )
        InitFatsecretApi(
          @start='start'
          @stop='stop'
        )
          Event(
            @event='$store.dispatch("action_foods_update")'
          )
          Event(
            @event='$store.dispatch("action_favorites_update")'
          )
          router-view
</template>

<script>
import CurrentUser from '@thomasphan/vue-components/src/components/CurrentUser.vue';
import Event from '@thomasphan/vue-components/src/components/Event.vue';
import InitFatsecretApi from '@thomasphan/vue-components/src/components/InitFatsecretApi.vue';
import ProgressInitFatsecretApi from './components/ProgressInitFatsecretApi.vue';
import TheUserIcon from '@thomasphan/vue-components/src/components/TheUserIcon.vue';
import Uid from '@thomasphan/vue-components/src/components/Uid.vue';

import LogRocket from 'logrocket';

import ButtonLoading from './components/ButtonLoading.vue';
import CardGettingStarted from './components/CardGettingStarted.vue';

const mounted = function ()
{
  LogRocket.init('tcqwmw/eatandtrack');
};

export default
{
  components:
  {
    ButtonLoading,
    CardGettingStarted,
    CurrentUser,
    Event,
    InitFatsecretApi,
    ProgressInitFatsecretApi,
    TheUserIcon,
    Uid,
  },
  data()
  {
    return {
      VUE_APP_SITE_NAME: process.env.VUE_APP_SITE_NAME,
      shouldContinueAsGuest: false,
    };
  },
  mounted,
};
</script>