import { compact, concat, cond, defaultTo, get, identity, noop, pipe, reject, tap, uniqBy } from 'lodash/fp';

import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import { getFoods } from '@thomasphan/vue-components/src/functions/getFoods';
import { setFoods } from '@thomasphan/vue-components/src/functions/setFoods';

import food_calories from './food_calories.json';
import food_carbs from './food_carbs.json';
import food_fat from './food_fat.json';
import food_protein from './food_protein.json';
import workouts from './workouts.json';

import preferencesUnitModule from '@thomasphan/vue-components/src/store/preferencesUnit';

import {
  postDelegatedRequest2,
} from '@thomasphan/vue-components/src/functions/postDelegatedRequest2';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence
({
  storage: window.localStorage
});

const mutation = key => (state, value) =>
{
  state[key] = value;
};

const barcode = '850009273031';
const currentUser = null;
const favorites = [];
const food_id_calories = food_calories.food_id;
const food_id_carbs = food_carbs.food_id;
const food_id_fat = food_fat.food_id;
const food_id_protein = food_protein.food_id;
const foods = [];
const last = { food_entry: {} };
const recipe_food_name = 'My Recipe Name';
const recipe_ingredients = [];
const recipe_number_of_servings = 1;
const search_expression = 'avocado';

const barcode_update = mutation('barcode');
const currentUserUpdate = mutation('currentUser');
const favorites_update = mutation('favorites');
const food_calories_update = mutation('food_calories');
const food_carbs_update = mutation('food_carbs');
const food_fat_update = mutation('food_fat');
const food_protein_update = mutation('food_protein');
const food_id_calories_update = mutation('food_id_calories');
const food_id_carbs_update = mutation('food_id_carbs');
const food_id_fat_update = mutation('food_id_fat');
const food_id_protein_update = mutation('food_id_protein');
const foods_update = mutation('foods');
const last_update = mutation('last');
const recipe_food_name_update = mutation('recipe_food_name');
const recipe_ingredients_update = mutation('recipe_ingredients');
const recipe_number_of_servings_update = mutation('recipe_number_of_servings');
const search_expression_update = mutation('search_expression');
const workouts_update = mutation('workouts');

const recipe_food_name_reset = state =>
{
  state.recipe_food_name = recipe_food_name;
};

const recipe_ingredients_reset = state =>
{
  state.recipe_ingredients = recipe_ingredients;
};

const recipe_number_of_servings_reset = state =>
{
  state.recipe_number_of_servings = recipe_number_of_servings;
};

const action_foods_concat = async ({ dispatch, state }, { food }) =>
{
  await dispatch('action_foods_update');

  const foods = pipe
  ([
    get('foods'),
    concat(food),
    compact,
  ])
  (state);

  await setFoods(foods).catch(noop);

  await dispatch('action_foods_update');
};

const action_foods_reject = async ({ dispatch, state }, { food_id }) =>
{
  await dispatch('action_foods_update');

  const foods = pipe
  ([
    get('foods'),
    reject({ food_id }),
  ])
  (state);

  await setFoods(foods).catch(noop);

  await dispatch('action_foods_update');
};

const action_foods_update = ({ commit }) =>
(
  getFoods()
  .then(cond([[identity, identity]]))
  .then(defaultTo([]))
  .then(result => commit('foods_update', result))
  .catch(noop)
);

const action_favorites_update = ({ commit }) =>
(
  postDelegatedRequest2("foods.get_favorites.v2")({})
  .then(get('foods.food'))
  .then(defaultTo([]))
  .then(uniqBy('food_id'))
  .then(tap(result => commit('favorites_update', result)))
  .catch(noop)
);

const action_favorites_add = async ({ dispatch }, { food_id, serving_id }) =>
{
  await postDelegatedRequest2('food.add_favorite')({ food_id, serving_id });

  await dispatch('action_favorites_update');
};

const action_favorites_delete = async ({ dispatch }, { food_id, serving_id }) =>
{
  await postDelegatedRequest2('food.delete_favorite')({ food_id, serving_id });

  await dispatch('action_favorites_update');
};

export default new Vuex.Store
({
  state:
  {
    barcode,
    currentUser,
    favorites,
    food_calories,
    food_carbs,
    food_fat,
    food_protein,
    food_id_calories,
    food_id_carbs,
    food_id_fat,
    food_id_protein,
    foods,
    last,
    recipe_food_name,
    recipe_ingredients,
    recipe_number_of_servings,
    search_expression,
    workouts,
  },
  mutations:
  {
    barcode_update,
    currentUserUpdate,
    favorites_update,
    food_calories_update,
    food_carbs_update,
    food_fat_update,
    food_protein_update,
    food_id_calories_update,
    food_id_carbs_update,
    food_id_fat_update,
    food_id_protein_update,
    foods_update,
    last_update,
    recipe_food_name_reset,
    recipe_food_name_update,
    recipe_ingredients_reset,
    recipe_ingredients_update,
    recipe_number_of_servings_reset,
    recipe_number_of_servings_update,
    search_expression_update,
    workouts_update,
  },
  actions:
  {
    action_favorites_add,
    action_favorites_delete,
    action_favorites_update,
    action_foods_concat,
    action_foods_reject,
    action_foods_update,
  },
  modules:
  {
    preferencesUnitModule
  },
  plugins:
  [
    vuexLocal.plugin
  ],
});